import React, { Component } from 'react'
import Layout from '../components/layout/layout'
import CaseDetail from '../components/Cases/CaseDetail'
import Hero from '../components/Hero'

export default class TrainingAdvisoryCaseStudy extends Component {
  render() {
    const caseTopImage = require('../assets/images/rawpixel-701117-unsplash.jpg')
    const caseProblemImage = require('../assets/images/rawpixel-701117-unsplash.jpg')
    return (
      <Layout location={this.props.location}>
        <div className="gdlr-core-page-builder-body">
          <Hero
            title="Training & Advisory"
            description="Post Investment Support & Development"
          />
          <CaseDetail
            caseClient="Mastercard Foundation"
            caseTitle="Mastercard Foundation"
            caseDate="17 August 2017"
            caseTopImage={caseTopImage}
            caseProblemImage={caseProblemImage}
            caseHighlight={[
              'Successful adoption of technology in assessing programme suitability, selection, training and post-investment guidance to each of the qualifying SME',
              'Structured monthly workshops programme that targets ongoing guidance and knowledge transfer',
              'Doubled survival rate of SME under the programme with over 50% increase in market linkage opportunities.',
              'A sustainable and competent continuous improvement function',
            ]}
            caseDescription="Our client, a global development agency focusing on funding young entrepreneurs
            was struggling with structuring a programme that targets end-to-end due diligence,
            selection and support of the early stage businesses "
            caseProblemDescription="One of the main challenges
            was a poorly managed knowledge transfer facilitation that led to unaccountable
            and untraceable value adding support services to the young Entrepreneurs."
            caseProblemDescriptionHighlight="Bora Growth Partners was engaged as knowledge transfer facilitation partner to
            address the implementation and facilitation of post investment business support,
            training and mentoring of the funded entrepreneurs."
            caseFeedback="We were able to rely on Bora Growth team in implementing the support program to our East African cohorts with immediate and tangible outcome"
          />
        </div>
      </Layout>
    )
  }
}
